import { register } from 'register-service-worker';
import { alertController } from "@ionic/vue";

register(`${process.env.BASE_URL}service-worker.js`, {
	ready() {
		console.log('App is being served from cache by a service worker.');
	},
	
	registered() {
		console.log('Service worker has been registered.');
	},
	
	cached() {
		console.log('Content has been cached for offline use.');
	},
	
	updatefound(registration) {
		console.log('New service worker is downloading...');
	},
	
	updated(registration) {
		// Check if there is an active service worker before triggering the update alert
		if (!navigator.serviceWorker.controller) return;
		
		console.log('New content is available; showing update prompt.');
		
		async function presentUpdateAlert() {
			const alert = await alertController.create({
				header: 'De app is bijgewerkt met nieuwe functies!',
				message: `Houd rekening met de volgende vernieuwingen:

- In het leestenoverzicht zijn bij zowel de lengtemaat als het leesttype (wijdtemaat) '+' en '-' icoontjes toegevoegd. Hiermee kunt u de geselecteerde leesten aanpassen in lengte of wijdte en het verschil in afwijking beoordelen ten opzichte van uw ingevoerde voetmaten, voordat u doorgaat naar het modeloverzicht. Let er wel op dat u niet per ongeluk op een '+' of '-' icoon drukt als u een leest wilt selecteren!`,
				buttons: [
					{
						text: 'Herstarten',
						cssClass: "alert-button-confirm",
						id: 'confirm-button',
						handler: () => {
							registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
							//window.location.reload(true);
						},
					},
				],
				backdropDismiss: false,
			});
			return alert.present();
		}
		
		presentUpdateAlert();
	},
	
	offline() {
		console.log('No internet connection found. App is running in offline mode.');
	},
	
	error(error) {
		console.error('Error during service worker registration:', error);
	},
});